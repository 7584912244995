import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GameStatus, GameType, findGameStatus } from '../../utils/game.type';
import { getBetStakes } from '../../utils/global';

interface GamesState {
    gameIds: number[],
    games: GameType[],
    historyGames: GameType[]
}

const initialState: GamesState = {
    gameIds: [],
    games: [],
    historyGames: []
}

interface FetchGameIds {
    battle: any
}

interface FetchGames {
    battle: any,
    gameIds: number[]
}

interface fetchHistoryGames {
    battle: any,
    address: string
}

export const fetchGamesIds = createAsyncThunk('fetch/gameIds', async (data: FetchGameIds) => {
    const allGameIds: number[] = [];
    const gameIds = await data.battle.call('getAllActive', [1]);

    gameIds[0].map((item: number) => {
        allGameIds.push(Number(item))
    })

    return allGameIds
})

export const fetchGames = createAsyncThunk('fetch/games', async (data: FetchGames) => {
    const gamePromises = data.gameIds.map(async (id) => {
    const tx = await data.battle.call('getGame', [id]);
    const game: GameType = {
        id: Number(id),
        state: findGameStatus(Number(tx[0])),
        stakes: getBetStakes(tx[1]),
        player1: tx[2],
        player2: tx[3],
        winner: tx[4],
    };

    return game;
    });

    const gamesDetails = await Promise.all(gamePromises);
   
    return gamesDetails
})
  
export const fetchHistoryGames = createAsyncThunk('fetch/historyGames', async (data: fetchHistoryGames) => {
    const historyGames: GameType[] = [];
    
    const allGames = await data.battle.call('getAllPlayedOf', [data.address]);

    for await (const item of allGames.slice(-10)) {
        const tx = await data.battle.call('getGame', [item]);
        const game: GameType = {
            id: Number(item),
            state: findGameStatus(Number(tx[0])),
            stakes: getBetStakes(tx[1]),
            player1: tx[2],
            player2: tx[3],
            winner: tx[4],
        }
        historyGames.push(game);
    }

    return historyGames
})
    
export const GamesSlice = createSlice({
    name: 'games',
    initialState,
    reducers: {
        addGames: (state, action: PayloadAction<{ id: number, state: GameStatus, stakes: string, player1: string, player2: string, winner: string }>) => {
            state.games.push({
                id: action.payload.id,
                state: action.payload.state,
                stakes: action.payload.state,
                player1: action.payload.player1,
                player2: action.payload.player2,
                winner: action.payload.winner
            })
        },
        removeItemGame: (state) => {
            state.gameIds.shift()
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGamesIds.fulfilled, (state, action) => {
            state.gameIds = action.payload
        })
        builder.addCase(fetchGames.fulfilled, (state, action) => {
            state.games = action.payload
        })
         builder.addCase(fetchHistoryGames.fulfilled, (state, action) => {
            state.historyGames = action.payload
        })
    }
})


export default GamesSlice.reducer;

export const { addGames, removeItemGame } = GamesSlice.actions;