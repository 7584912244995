import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getDrawPercentage, getLoosePercentage, getLost, getMatches, getWinPercentage, getWins } from '../../hooks/useBattleContractFunctions';
import { getNftToken } from '../../hooks/useNftContractFunctions';

interface ItemStats {
    name: string,
    count: string,
    icon?: string
}

interface Stats {
    stats: ItemStats[]
}

const initialState: Stats = {
    stats: []
}

interface fetchAccountStats {
    battle: any,
    amber: any,
    nft: any,
    address: string
}

export const fetchStats = createAsyncThunk('fetch/accountStats', async (data: fetchAccountStats) => {
    const accountStats: ItemStats[] = [];

    accountStats.push({ name: 'NFT Mushrooms', count: await getNftToken(data.nft, data.address) || '0', icon: 'nft.svg' })
    accountStats.push({ name: 'Coins', count: '0', icon: 'coins.svg' })
    accountStats.push({ name: 'Matches', count: await getMatches(data.battle, data.address) || '0', icon: 'matches.svg' })
    accountStats.push({ name: 'Loses', count: await getLost(data.battle, data.address) || '0', icon: 'loses.svg'  })
    accountStats.push({ name: 'Wins', count: await getWins(data.battle, data.address) || '0', icon: 'wins.svg' })
    const losesCount = parseInt(accountStats.find(item => item.name === 'Loses')?.count || '0');
    const winsCount = parseInt(accountStats.find(item => item.name === 'Wins')?.count || '0');
    const matchesCount = parseInt(accountStats.find(item => item.name === 'Matches')?.count || '0');
    accountStats.push({ name: 'Draw', count: String(matchesCount - (losesCount + winsCount)) || '0' })
    accountStats.push({ name: 'Win Percentage', count: await getWinPercentage(data.battle, data.address) || '0' })
    accountStats.push({ name: 'Loose Percentage', count: await getLoosePercentage(data.battle, data.address) || '0' })
    accountStats.push({ name: 'Draw Percentage', count: await getDrawPercentage(data.battle, data.address) || '0'})


    return accountStats
})

export const StatsSlice = createSlice({
    name: 'stats',
    initialState,
    reducers: {
        addStats: (state, action: PayloadAction<{name: string, count:string}>) => {
            state.stats.push({
                name: action.payload.name,
                count: action.payload.count
            })
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchStats.fulfilled, (state, action) => {
            state.stats = action.payload
        })
         
    }
})

export default StatsSlice.reducer;

export const { addStats } = StatsSlice.actions;