export enum GameStatus {
  noPlyer = 'HASNOTPLAYER',
  twoPlayers = 'HASPLAYERTWO',
  onePlayer = 'HASPLAYERONE',
  hasWinner = 'HASWINNER',
}

export interface GameType {
  id: number;
  state: GameStatus;
  stakes: string;
  player1: string;
  player2: string;
  winner: string;
}

export const getStatus = (status: GameStatus) => {
  switch (status) {
    case 'HASNOTPLAYER':
      return 'NO PLAYER';
    case 'HASPLAYERONE':
      return 'Waiting';
    case 'HASPLAYERTWO':
      return 'In progress';
    case 'HASWINNER':
      return 'Finished';
  }
};


export const findGameStatus = (status: number): GameStatus =>{
    switch (status) {
     case 0:
       return GameStatus.noPlyer;
     case 1:
       return GameStatus.onePlayer;
     case 2:
       return GameStatus.twoPlayers;
     case 3:
       return GameStatus.hasWinner;
 
     default:
       return GameStatus.noPlyer;
   }
 };