import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { makeAddressShortForGame } from '../../utils/global';

interface Playing {
    playing: boolean | undefined
}

const initialState: Playing = {
    playing: false
}

interface FetchPlayingOn {
    battle: any,
    address: string,
    sdk?: any
}

let waiting = true;
export const fetchPlayingOn = createAsyncThunk('contract/call', async (data: FetchPlayingOn) => {    
    
    const gameId = await data.battle.call('playingOn', [data.address])
    
    if (gameId > 0) {
        const game = await data.battle.call('getGame', [gameId]);
       
        if (Number(game[0]) === 2 && data.sdk && waiting) {
            waiting = false
            const signature = await data.sdk.wallet.sign(gameId.toString());
            const url = `https://${process.env.NEXT_PUBLIC_GAME_URL}?game=${gameId}&address=${data.address}&user=${makeAddressShortForGame(data.address)}&token=${signature}`;
            window.open(url, '_self');
        }
    }
    return gameId > 0
})

export const PlayingSlice = createSlice({
    name: 'player',
    initialState,
    reducers: {
        checkPlaying: (state, action: PayloadAction<{status: boolean}>) => {
            state.playing = action.payload.status
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPlayingOn.fulfilled, (state, action) => {
            state.playing = action.payload
        })
    }
})

export default PlayingSlice.reducer;

export const { checkPlaying } = PlayingSlice.actions;