import '../styles/globals.css';
import React from 'react';
import dynamic from 'next/dynamic';
import type { AppProps } from 'next/app';
import { ThirdwebProvider } from '@thirdweb-dev/react';
import { Polygon } from '@thirdweb-dev/chains';
import {
  metamaskWallet,
  walletConnect,
  coinbaseWallet,
  phantomWallet,
  embeddedWallet
} from '@thirdweb-dev/react';
const Header = dynamic(() => import('../components/blocks/header'));
const Footer = dynamic(() => import('../components/blocks/footer'));

import { Provider } from 'react-redux';
import { store } from '../store/store';
import Head from 'next/head';

export default function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ThirdwebProvider
      clientId={process.env.NEXT_PUBLIC_TEMPLATE_CLIENT_ID}
      supportedChains={[Polygon]}
      activeChain={Polygon}
      supportedWallets={[embeddedWallet(), metamaskWallet(), walletConnect(), coinbaseWallet(), phantomWallet()]}
      dAppMeta={{
        name: 'Decision Crypto Game',
        description:
          'Innovative card game that merges NFTs, Battle for Crypto, Staking, Mining, DeFi, & GameFi.',
        logoUrl: 'https://decision.bet/favicon.ico',
        url: 'https://decision.bet/',
        isDarkMode: true
      }}
    >
      <Provider store={store}>
        <Head>
          <title>Decision Card Game</title>
          <meta name="description" content="Innovative card game that merges NFTs, Battle for Crypto, Staking, Mining, DeFi, & GameFi." />
        </Head>

        <Header />
        <Component {...pageProps} />
        <Footer />
      </Provider>
    </ThirdwebProvider>
  );
}
