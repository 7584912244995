import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface Loading {
    loading: boolean
}

const initialState: Loading = {
    loading: false
}

export const LoadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        addLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        }
        
    }
})

export default LoadingSlice.reducer;

export const { addLoading } = LoadingSlice.actions;