import {etherToWei, getTransactionOptions, weiToEther} from '../utils/global';

export const startingCreateGame = async (battle: any, bet: number) => {
    const inWei = etherToWei(bet.toString());
    return await battle.call('createGame', [inWei], await getTransactionOptions())
};

export const startingCancelGame = async (battle: any, gameId: number) => {
    return await battle.call('cancel', [gameId]);
};

export const startingJoinGame = async (battle: any, id: number): Promise<boolean> => {
    return await battle.call('joinGame', [id], await getTransactionOptions())
};

export const getMatches = async (battle: any, address: string) => {
    return String(`${Number(await battle.call('getTotalPlayedOf', [address]))}`)
}

export const getLost = async (battle: any, address: string) => {
    return String(await battle.call('getTotalLostOf', [address]));
}

export const getWins = async (battle: any, address: string) => {
    return String(await battle.call('getTotalWonOf', [address]));
}


export const getDrawPercentage = async (battle: any, address: string) => {
    try { 
        const drawPercentage = Number(weiToEther(await battle.call('getDrawPercentageOf', [address]))).toFixed(2);
        return String(drawPercentage + ' %');
    } catch (err) {
        return String('0.00 %');
    }
}

export const getLoosePercentage = async (battle: any, address: string) => {
    try { 
        const loosePercentage = Number(weiToEther(await battle.call('getLoosePercentageOf', [address]))).toFixed(2);
        return String(loosePercentage + ' %');
    } catch (err) {
        return String('0.00 %');
    }
}

export const getWinPercentage = async (battle: any, address: string) => {
    try {
        const winPercentage = Number(weiToEther(await battle.call('getWinPercentageOf', [address]))).toFixed(2);
        return String(winPercentage + ' %');
    }
    catch (err) {
       return String('0.00 %');
    }
}