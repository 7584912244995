import {BigNumber, ethers} from 'ethers';

export function makeAddressShort(address: string, length = 4) {
  return `${address.substring(0, length + 2)}…${address.substring(
    address.length - length
  )}`;
}

export function makeAddressShortForGame(address: string, length = 4) {
  // return address.substring(0, length);
  return `${address.substring(0, length)}...${address.substring(
    address.length - length
  )}`;
}

export const weiToEther = (amount: BigNumber): string => {
  return ethers.utils.formatEther(amount);
};

export const etherToWei = (amount: string): BigNumber => {
  return ethers.utils.parseEther(amount);
};

export const getBetStakes = (bet: number): string => {
    return weiToEther(BigNumber.from(`${bet}`)) || '0'
  };


  export const getTransactionOptions = async (): Promise<object> => {
    const gasLimit = 500000;
    const gasValue = etherToWei('0.05')
  
    type Overrides = {
      gasLimit: number,
      value: BigNumber
    }
    const overrides: Overrides = {
      gasLimit: gasLimit,
      value: gasValue
    };

  return overrides;
};