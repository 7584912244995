import { configureStore } from '@reduxjs/toolkit'; 

import { AlertSlice } from './alert/alertSlice';
import { LoadingSlice } from './loading/loadingSlice';
import { GamesSlice } from './games/gamesSlice'

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { PlayingSlice } from './play/playingSlice';
import { StatsSlice } from './stats/statsSlice';

export const store = configureStore({
    reducer: {
        alert: AlertSlice.reducer,
        loader: LoadingSlice.reducer,
        player: PlayingSlice.reducer,
        game: GamesSlice.reducer,
        stats: StatsSlice.reducer
    }
})

export const useAppDispatch: () => typeof store.dispatch = useDispatch

export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector